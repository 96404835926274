import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

// components
import ChatBubble from 'components/pageECoach/ChatBubble';
import Chatbar from 'components/pageECoach/Chatbar';

export default function PromptSetup(props) {
  const { chatGuideData, onSubmit } = props;
  const { t } = useTranslation('pageEcoach');
  const challengeText = t("The challenge I'm facing at work is figuring out");

  const setupInitialInput = () => {
    if (chatGuideData.promptHelper.goal === 'Something else')
      return `${challengeText} ${t(
        'how to'
      )} ${chatGuideData.promptHelper.theme.toLowerCase()} `;
    return `${challengeText} ${chatGuideData.promptHelper.goal.prompt || ''}`;
  };

  return (
    <>
      <ChatBubble
        simpleString={t(
          "promptHelper.I've filled in the chat bar based on your answers. Feel free to add more details and submit your challenge."
        )}
        pointer={true}
      />
      {chatGuideData.chatStartEnded === false && (
        <Chatbar
          autoFocus
          initialInput={setupInitialInput()}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
}

PromptSetup.propTypes = {
  chatGuideData: PropTypes.object,
  onSubmit: PropTypes.func,
};
