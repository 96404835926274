import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import * as Cards from 'components/pageECoach/_styles/Cards.style';
import colours from 'constants/colours';
import device from 'constants/devices';
import * as Text from 'components/_styles/Text.style';

export const ChatBubble = styled(Cards.Card)`
  p {
    color: ${colours.neutralCool[800]};
  }
`;

export const Label = styled(Text.P1Semibold)`
  color: ${colours.neutralCool[600]};
`;

export const Section = styled('div')`
  align-items: ${(props) => (props.eCoach ? 'flex-start' : 'flex-end')};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

// ecoach chat styles

export const ECoachBubbleWithPointer = styled(ChatBubble)`
  border-radius: 20px 20px 20px 0;
  width: fit-content;

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: -1px;
    width: 0;
    height: 0;
    border-style: solid;
    border-left: 0;
    border-bottom: 0;
    margin-top: -10px;
    margin-left: -20px;
  }

  /* this border color controlls the color of the triangle (what looks like the fill of the triangle) */
  &:after {
    left: 0.5px;
    bottom: 0;
    border-color: transparent white transparent transparent;
    border-width: 21px;
  }
`;

export const Headers = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Navigation = styled('div')`
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 5px;
  margin-left: -5px;
  svg {
    height: 15px;
    width: 15px;
  }
  svg path {
    stroke: ${colours.neutralCool[800]};
  }
  text-decoration: underline;
`;

export const Subheader = styled(Text.P1)`
  color: ${colours.neutralCool[500]};
`;

// user chat styles

export const EditInputSection = styled('div')`
  align-items: flex-end;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const UserBubbleWithPointer = styled(ChatBubble)`
  border-radius: 20px 20px 0;
  gap: 10px;
  padding: 30px;
  width: fit-content;

  @media (max-width: ${device.tablet}) {
    padding: 20px;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-left-color: ${colours.shade[0]};
    border-right: 0;
    border-bottom: 0;
    margin-top: -10px;
    margin-right: -19px;
  }
`;

export const DescriptionText = styled(Text.P2)`
  color: ${colours.neutralCool[800]};
`;

export const ButtonArea = styled('div')`
  display: flex;
  justify-content: flex-end;
`;

export const SubmitButton = styled(IconButton)`
  background-color: ${colours.primary[500]};
  border-radius: 10px;

  &:hover {
    background-color: ${colours.primary[600]};
  }
  svg path {
    stroke: ${(props) =>
      props.disabled ? colours.neutralCool[300] : colours.shade[0]};
  }
`;
