import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

// components
import Chatbar from 'components/pageECoach/Chatbar';
import ClickableInput from 'components/pageECoach/ClickableInput';

// hooks
import { useUser } from 'contexts/UserContext';

// styles
import colours from 'constants/colours';
import * as InputStyle from 'components/pageECoach/_styles/MessageInputs.style';
import * as Text from 'components/_styles/Text.style';

export default function ChatLanding({ onSubmit, openChatGuide }) {
  const { t } = useTranslation('pageEcoach');
  const { data: user } = useUser();

  const emergencyLink = () => {
    const docPageURL = `/documents/195b172d-f368-4e53-83af-a634027ef450/pdf?language=${user.language}`;
    window.open(docPageURL, '_blank');
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
        <InputStyle.ButtonsColumn>
          <ClickableInput
            inputAction={openChatGuide}
            inputText={t('Help me get started')}
            inputType="primary"
          />
          {user.organization.name === 'Ardene' && (
            <Text.P1Semibold
              onClick={emergencyLink}
              style={{ color: colours.blue[500], cursor: 'pointer' }}
            >
              {t('Click here in case of emergency')}
            </Text.P1Semibold>
          )}
        </InputStyle.ButtonsColumn>
        <Chatbar autoFocus initialInput="" onSubmit={onSubmit} />
      </div>
    </div>
  );
}

ChatLanding.propTypes = {
  onSubmit: PropTypes.func,
  openChatGuide: PropTypes.func,
};
