import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { Communication } from 'untitledui-js';

// styles
import * as StyledChatbar from 'components/pageECoach/_styles/Chatbar.style';

export default function Chatbar({ initialInput, onSubmit, autoFocus }) {
  const [pendingInput, setPendingInput] = useState(initialInput);

  const handleSubmit = () => {
    onSubmit(pendingInput);
    setPendingInput('');
  };

  return (
    <StyledChatbar.Section>
      <Paper
        sx={{
          alignItems: 'center',
          borderRadius: '20px',
          boxShadow: 7,
          display: 'flex',
          justifyContent: 'space-between',
          p: '6px 12px 6px 20px',
        }}
      >
        <TextField
          onChange={(input) => setPendingInput(input.target.value)}
          placeholder="Message eCoach"
          inputProps={{ 'aria-label': 'Message eCoach' }}
          variant="standard"
          multiline
          InputProps={{
            disableUnderline: true,
            autoFocus,
          }}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              if (pendingInput.length !== 0) handleSubmit();
              ev.preventDefault();
            }
          }}
          sx={{
            flexGrow: 1,
          }}
          value={pendingInput}
        />
        <StyledChatbar.Button
          type="submit"
          aria-label="submit"
          disabled={pendingInput.length === 0}
          onClick={handleSubmit}
        >
          <Communication.Send01 strokeWidth={1.5} />
        </StyledChatbar.Button>
      </Paper>
    </StyledChatbar.Section>
  );
}

Chatbar.propTypes = {
  initialInput: PropTypes.string,
  onSubmit: PropTypes.func,
  autoFocus: PropTypes.bool,
};
