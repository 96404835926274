import styled from 'styled-components';
import colours from 'constants/colours';
import * as Text from 'components/_styles/Text.style';

export const Error = styled('div')`
  align-items: center;
  background-color: ${colours.error[50]};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  padding: 20px;
`;

export const ErrorMessage = styled(Text.P2Semibold)`
  color: ${colours.error[800]};
  text-align: center;
`;

export const ErrorMessageList = styled('ol')`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-inline-start: 20px;
  white-space: pre-line;
`;

export const ErrorMessageSmall = styled('li')`
  color: ${colours.error[800]};
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  margin: 0;
`;

export const ResendTimed = styled(Text.P2Semibold)`
  color: ${colours.blue[300]};
  cursor: not-allowed;
  text-align: center;
`;

export const Resend = styled(Text.P2Semibold)`
  color: ${colours.blue[500]};
  cursor: pointer;
  text-align: center;
`;
